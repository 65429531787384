.user-details {
  @apply grid grid-cols-12 gap-x-2 items-start;

  > div,
  > span {
    text-wrap: wrap;
    @apply mb-1 break-words sm:text-base;

    &:nth-child(odd) {
      @apply font-semibold col-span-5;
    }
    &:nth-child(even) {
      @apply col-span-7;
    }
  }
}

.user-documents {
  > div {
    @apply flex mb-2;

    span {
      @apply text-lg font-semibold;

      &:first-child {
        @apply w-48;
      }
    }
  }
}

.user-row-controls {
  .row {
    @apply flex items-center mb-1;

    span {
      text-wrap: wrap;
      @apply sm:text-base;

      &:first-child {
        @apply w-32;
      }
    }
  }
}

.authorization-controls {
  @apply flex items-center justify-center rounded p-1 gap-1;

  .button {
    @apply w-full text-center inline-block px-2 py-2 sm:py-3 transition-all ease-in-out duration-150 rounded bg-bg-btn-neutral bg-opacity-50 text-white;

    span {
      @apply text-text-btn text-sm sm:text-base;
    }

    &.active {
      @apply bg-bg-btn-neutral-active bg-opacity-50 opacity-100;
    }
  }

  &.allowed {
    .button {
      @apply hover:bg-blue-300 bg-opacity-100 cursor-pointer;
    }
  }

  &.not-allowed {
    @apply cursor-not-allowed opacity-50;
  }
}

.user-row-document-details {
  @apply grid grid-cols-12 gap-x-2 items-center;

  span,
  div {
    text-wrap: wrap;
    @apply break-words sm:text-base;

    &:nth-child(odd) {
      @apply col-span-7 xl:col-span-6;
    }
    &:nth-child(even) {
      @apply col-span-5 xl:col-span-6;
    }
  }
}

.user-messages-table {
  .user-messages-cell {
    @apply relative;

    .cell-wrapper {
      @apply grid grid-cols-12 items-center gap-2;
    }

    .cell-inner {
      @apply flex items-center flex-wrap;
    }
  }
}
