.invoice-card {
  .detail-row {
    @apply leading-4 break-all;
  }

  .invoice-header {
    @apply grid grid-cols-2 sm:flex gap-2 justify-between;

    .details-left {
      @apply text-[15px] flex flex-col gap-2;
    }
    .details-right {
      @apply text-[15px] flex flex-col gap-2 text-right;
    }
  }

  .invoice-items {
    @apply text-[15px] mt-3 sm:mt-2 text-center;

    .invoice-items-list {
      @apply bg-bg-table-main shadow-inner mt-2 rounded px-2 py-1;

      .invoice-item {
        @apply flex gap-2 justify-between;
      }
    }
  }

  .invoice-footer {
    @apply text-[15px] flex items-start justify-between gap-5 mt-4 leading-4;

    .details-left {
      @apply flex flex-col gap-2;
    }
    .details-right {
      @apply flex flex-col gap-2 text-right;
    }
  }
}
