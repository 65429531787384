.footer {
  .wrapper {
    @apply flex flex-col sm:flex-row w-full items-center justify-between gap-y-1 sm:gap-y-2;

    .copyrights {
      @apply text-xs text-gray-300;
    }

    .version {
      @apply text-gray-500 text-xs;
    }
  }
}
