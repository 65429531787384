.settings-content {
  @apply px-2 sm:px-4 md:px-8;

  .row {
    @apply border-b border-solid border-border-sidebar py-4 md:py-8;

    .content {
      @apply w-full 2xl:w-3/4;

      .title {
        @apply flex items-center gap-4 text-text-main;
      }

      .description {
        @apply mt-2 pl-8 flex justify-between gap-4;

        span {
          @apply text-text-sub;
        }
      }

      .form-fields {
        @apply mt-4 pl-8 flex items-center gap-4;
      }
    }
  }
}

.campaign-section {
  @apply py-3 md:py-6 border-b border-solid border-border-sidebar;

  &:last-child {
    @apply mb-10;
  }

  > .title {
    @apply text-xl text-text-main mb-4;
  }

  .cards {
    @apply flex flex-wrap gap-4 sm:gap-5 md:gap-10 justify-start;

    .not-found {
      @apply text-text-main text-sm;
    }

    .card-wrapper {
      @apply relative;

      .edit {
        @apply z-0 absolute pl-2 pr-1.5 py-2 flex flex-col gap-2 border border-solid border-border-table-body bg-[black] bg-opacity-5 rounded-tr-xl rounded-br-xl right-0 top-4 text-text-main cursor-pointer transition-all ease-in-out duration-150;
      }

      &:hover {
        .edit {
          @apply -right-7;
        }
      }

      .card {
        @apply px-4 pb-4 pt-3 rounded-xl bg-bg-table-main border border-solid border-border-table-body text-text-main shadow-lg;

        > .title {
          @apply flex items-start gap-10 justify-between mb-2;
        }

        .discount-props {
          @apply flex items-center justify-end gap-1 md:gap-2 mb-4;
        }

        .dates {
          @apply flex items-center gap-2 md:gap-4 justify-between;

          .date {
            @apply flex flex-col items-center;

            .title {
              @apply text-lg font-bold mb-2;
            }

            .date-wrapper {
              @apply flex flex-col items-center bg-bg-card-main border border-solid border-border-table-body p-2 rounded-lg;

              .dmy {
                @apply flex flex-col items-center;
              }
            }
          }
        }
      }
    }
  }
}

.happy-hour-wrapper {
  @apply py-5;

  .card {
    @apply rounded-xl bg-bg-table-main border border-border-table-body p-4 xl:p-5 relative;

    .title {
      @apply flex items-end gap-10 text-text-main;
    }
  }
}
