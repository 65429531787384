.header {
  @apply relative flex items-center w-screen bg-bg-header shadow px-2 md:px-4;

  .wrapper {
    @apply relative container mx-auto flex flex-row items-center justify-between;

    .logo {
      @apply p-[2px] w-10 h-10 md:w-60 md:h-fit rounded-full md:rounded;

      img {
        @apply w-full h-full object-cover object-left;
      }
    }

    .notifications-panel {
      @apply z-50 absolute w-94 top-12 -right-14 px-2 py-3 rounded text-text-main bg-bg-notifications-panel border border-border-main;

      &:after {
        content: " ";
        @apply absolute -top-1 right-18 w-2 h-2 rotate-45 border-t border-l border-border-notifications-panel bg-bg-notifications-panel;
      }

      .title {
        @apply flex items-center justify-between border-b border-border-notifications-panel pb-2 px-2;

        > span {
          @apply text-2xl font-bold;
        }
      }

      .list {
        @apply pt-2 relative min-h-14 max-h-[500px] overflow-y-scroll p-2;

        .item {
          @apply z-10 flex flex-col hover:opacity-75 cursor-pointer transition-all ease-in-out duration-100 mb-3;

          &:last-child {
            @apply mb-0;
          }
        }
      }
    }

    .user-menu {
      @apply z-50 absolute top-12 right-0 flex w-max flex-col rounded bg-bg-notifications-panel border border-border-notifications-panel shadow p-2 text-text-main;

      &:after {
        content: " ";
        @apply absolute -top-1 right-4 w-2 h-2 rotate-45 border-t border-l border-border-notifications-panel bg-bg-notifications-panel;
      }

      .item {
        @apply px-4 py-2 transition-all ease-in-out duration-100 rounded-md;

        + .hoverable {
          @apply hover:bg-[black] hover:bg-opacity-15 cursor-pointer;
        }
        + .active {
          @apply bg-[black] bg-opacity-15;
        }
      }
    }

    .menu {
      @apply flex text-gray-100 text-base xl:text-xl;

      .item {
        @apply relative cursor-pointer px-4 py-1.5 rounded-lg;
        a {
          @apply px-4 py-2 cursor-pointer no-underline transition-all ease-in-out duration-500;
        }

        &:hover {
          @apply bg-[black] bg-opacity-15 transition-all ease-in-out duration-100;
        }

        &:hover {
          .submenu {
            @apply flex;
          }
        }
      }
      .item.active {
        @apply bg-[black] bg-opacity-15;
      }

      .submenu {
        @apply hidden cursor-default z-50 absolute flex flex-col gap-1 left-1/2 transform -translate-x-1/2 top-0 w-max p-2 mt-12 bg-bg-submenu text-text-main text-base rounded-lg shadow-lg transition-all ease-in-out duration-100;

        &:before {
          content: "";
          @apply absolute w-3 h-3 bg-bg-submenu rotate-45 -top-[6px] left-1/2 transform -translate-x-1/2;
        }
        &:after {
          content: "";
          @apply absolute w-full h-4 left-0 top-[-15px] z-50;
        }

        a {
          @apply text-center rounded-lg;

          &:hover {
            @apply bg-[black] bg-opacity-15 transition-all ease-in-out duration-100;
          }
        }
        a.active {
          @apply bg-[black] bg-opacity-15;
        }
      }
    }

    .header-icon-button {
      @apply items-center justify-center bg-gray-900 bg-opacity-20 rounded-full w-10 h-10 shadow cursor-pointer;
    }
  }

  .mobile-menu {
    @apply absolute z-100 shadow lg:hidden flex-col bg-bg-main text-text-main top-0 right-0 mr-0 sm:mr-10 transform rounded-bl mt-14 md:mt-24 transition-all ease-in-out duration-100;

    .menu-links {
      @apply flex flex-col py-1 bg-bg-mobile-menu-body;
      a {
        @apply pl-10 py-1 pr-10;
      }

      a.active {
        @apply font-bold underline;
      }
    }
  }

  .closed {
    @apply hidden;
  }

  .opened {
    @apply flex;
  }
}
