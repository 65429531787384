.renting-details {
  @apply grid grid-cols-12 gap-x-2;

  > span {
    text-wrap: wrap;
    @apply mb-1 break-words;

    &:nth-child(odd) {
      @apply flex items-center font-semibold col-span-4;
    }
    &:nth-child(even) {
      @apply col-span-8;
    }
  }

  > div {
    @apply mb-1;

    &:nth-child(odd) {
      @apply flex items-center col-span-5;
    }
    &:nth-child(even) {
      @apply col-span-7;
    }
  }
}

.route-marker {
  @apply relative z-20 bg-white p-1 rounded border border-solid border-gray-400 shadow-lg;

  &:after {
    content: " ";
    @apply absolute left-1/2 transform -translate-x-1/2 bottom-0 translate-y-1/2 w-3 h-3 rotate-45 bg-white border-r border-b border-solid border-gray-400;
  }

  span {
    @apply block text-xs w-max font-semibold;
  }
}

.res-wrapper {
  .res-row {
    @apply flex items-center px-2 md:px-10 lg:px-10 py-6;

    .half-icon {
      clip-path: inset(0 0 50% 0); /* Cuts the bottom half */
    }

    .res-box,
    .ride-box {
      @apply relative flex items-center px-0 sm:px-4 py-6 border-t-3 border-b-3 border-solid border-[#555] text-center;

      .label {
        @apply relative text-xs sm:text-base text-text-main px-0 sm:px-4;
      }

      .strikethrough {
        @apply border-t border-[#555] flex-grow;
      }
    }

    .res-box {
      @apply border-l-4 rounded-l;

      .zigzag {
        @apply rotate-180 h-full absolute z-10 -right-[3px] top-0 object-cover;
      }

      .timestamp-start,
      .timestamp-end {
        @apply absolute -bottom-7.5;

        &:before {
          content: " ";
          @apply absolute -top-1 left-1/2 -translate-x-1/2 w-2 h-2 rotate-45 border-t-2 border-l-2 border-[#555] bg-bg-main;
        }
      }

      .timestamp-start {
        @apply left-0 -translate-x-1/2;
      }
      .timestamp-end {
        @apply right-1 translate-x-1/2;
      }
    }

    .ride-box {
      @apply border-r-4 rounded-r;

      .zagzig {
        @apply h-full absolute left-0 top-0 object-cover;
      }

      .timestamp-start,
      .timestamp-end {
        @apply absolute -top-7.5;

        &:before {
          content: " ";
          @apply absolute -bottom-1 left-1/2 -translate-x-1/2 w-2 h-2 -rotate-135 border-t-2 border-l-2 border-[#555] bg-bg-main;
        }
      }

      .timestamp-start {
        @apply left-2 -translate-x-1/2;
      }
      .timestamp-end {
        @apply right-0 translate-x-1/2;
      }
    }
  }
}
