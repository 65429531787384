.rotated0 {
  .yarl__slide_image {
    @apply rotate-0;
  }
}
.rotated90 {
  .yarl__slide_image {
    @apply rotate-90;
  }
}
.rotated180 {
  .yarl__slide_image {
    @apply rotate-180;
  }
}
.rotated270 {
  .yarl__slide_image {
    @apply -rotate-90;
  }
}
