.dashboard-block {
  @apply relative text-text-main bg-bg-card-main rounded-lg px-4 py-2 shadow-lg border border-solid border-border-card-main;

  .title {
    @apply font-medium text-center mb-4;

    span {
      @apply text-xl;
    }
  }
}
