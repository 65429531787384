@tailwind base;
@tailwind components;
@tailwind utilities;

.light {
  --text-main: 59, 59, 59;
  --text-sub: 130, 130, 130;
  --text-error: 220, 38, 38;
  --link-main: 83, 153, 255;
  --checkbox: 255, 255, 255;
  --text-btn: 255, 255, 255;
  --text-button-title: 0, 0, 0;

  --bg-main: 245, 245, 245;
  --bg-settings: 252, 252, 252;
  --bg-header: 10, 81, 255, 0.8;
  --bg-sidebar: 245, 245, 245;
  --bg-confirm-wrapper: 230, 230, 230;
  --bg-confirm-content: 245, 245, 245;
  --bg-notifications-panel: 250, 250, 250;
  --bg-input: 255, 255, 255;
  --bg-input-disabled: 240, 240, 240;
  --bg-modal: 243, 244, 246;
  --bg-card-main: 255, 255, 255;
  --bg-table-main: 250, 250, 250;
  --bg-table-expand: 244, 244, 244;
  --bg-checkbox-selected: 96, 165, 250;
  --bg-radio-selected: 96, 165, 250;
  --bg-select-option-hover: 219, 234, 254;
  --bg-btn-primary: 59, 130, 246;
  --bg-btn-neutral: 209, 213, 219;
  --bg-btn-neutral-active: 96, 165, 250;
  --bg-btn-cancel: 209, 213, 219;
  --bg-mobile-menu-body: 255, 255, 255;
  --bg-submenu: 255, 255, 255;
  --bg-payment-card: 240, 240, 240;
  --bg-timeline-dot: 255, 255, 255;
  --bg-timeline-content: 255, 255, 255;

  --border-main: 229, 231, 235;
  --border-sidebar: 225, 225, 225;
  --border-confirm-wrapper: 180, 180, 180;
  --border-notifications-panel: 209, 213, 219;
  --border-card-main: 229, 231, 235;
  --border-card-main-no-bg: 209, 213, 219;
  --border-tabs: 209, 213, 219;
  --border-input: 203, 213, 225;
  --border-input-hover: 156, 163, 175;
  --border-input-focus: 59, 130, 246;
  --border-tabs-active: 10, 10, 10;
  --border-checkbox-selected: 96, 165, 250;
  --border-radio-selected: 96, 165, 250;
  --border-table-header: 156, 163, 175;
  --border-table-body: 209, 213, 219;
  --border-button-title: 3, 7, 18;
  --border-submenu-underline: 170, 170, 170;
  --border-submenu-underline-hover: 100, 100, 100;
}

.dark {
  --text-main: 243, 244, 246;
  --text-sub: 180, 180, 180;
  --text-error: 248, 113, 113;
  --link-main: 83, 153, 255;
  --checkbox: 59, 59, 59;
  --text-btn: 59, 59, 59;
  --text-button-title: 255, 255, 255;

  --bg-main: 59, 59, 59;
  --bg-settings: 59, 59, 59;
  --bg-header: 44, 44, 44;
  --bg-sidebar: 55, 55, 55;
  --bg-confirm-wrapper: 55, 55, 55;
  --bg-confirm-content: 80, 80, 80;
  --bg-notifications-panel: 59, 59, 59;
  --bg-input: 59, 59, 59;
  --bg-input-disabled: 75, 75, 75;
  --bg-modal: 70, 70, 70;
  --bg-card-main: 93, 93, 93;
  --bg-table-main: 67, 67, 67;
  --bg-table-expand: 59, 59, 59;
  --bg-checkbox-selected: 170, 170, 170;
  --bg-radio-selected: 170, 170, 170;
  --bg-select-option-hover: 75, 75, 75;
  --bg-btn-primary: 59, 130, 246;
  --bg-btn-neutral: 209, 213, 219;
  --bg-btn-neutral-active: 96, 165, 250;
  --bg-btn-cancel: 107, 114, 128;
  --bg-mobile-menu-body: 50, 50, 50;
  --bg-submenu: 80, 80, 80;
  --bg-payment-card: 47, 47, 47;
  --bg-timeline-dot: 70, 70, 70;
  --bg-timeline-content: 70, 70, 70;

  --border-main: 93, 93, 93;
  --border-sidebar: 44, 44, 44;
  --border-confirm-wrapper: 40, 40, 40;
  --border-notifications-panel: 88, 88, 88;
  --border-card-main: 119, 119, 119;
  --border-card-main-no-bg: 119, 119, 119;
  --border-tabs: 255, 255, 255;
  --border-input: 119, 119, 119;
  --border-input-hover: 156, 163, 175;
  --border-input-focus: 59, 130, 246;
  --border-tabs-active: 255, 255, 255;
  --border-checkbox-selected: 170, 170, 170;
  --border-radio-selected: 170, 170, 170;
  --border-table-header: 130, 130, 130;
  --border-table-body: 88, 88, 88;
  --border-button-title: 255, 255, 255;
  --border-submenu-underline: 120, 120, 120;
  --border-submenu-underline-hover: 190, 190, 190;
}
