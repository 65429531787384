.toggler {
  transition: all 0.2s ease-in;

  .thumb-enabled {
    left: 16px;
    transition: all 0.2s ease-in-out;
  }
  .thumb-disabled {
    left: 2px;
    transition: all 0.2s ease-in-out;
  }
}