.login-panel {
  @apply p-4;

  .login-container {
    @apply w-full sm:w-3/5 lg:w-1/2 xl:w-1/3 flex flex-col bg-bg-card-main mx-auto border border-solid border-border-card-main rounded p-4;

    .title {
      @apply text-text-main text-2xl md:text-3xl font-medium lg:font-bold;
    }

    input[type="text"],
    input[type="password"] {
      @apply p-2 md:p-4 text-base md:text-xl bg-bg-input border-2 border-border-input rounded transition ease-in-out duration-100;

      &::placeholder {
        color: #8f90a6;
      }
      &:hover {
        @apply border-border-input-hover;
      }
      &:focus {
        @apply border-border-input-focus;
      }

      &.is-valid {
        border-color: #27c24c !important;
      }
      &.is-invalid {
        border-color: #f05050 !important;
      }
      &.disabled {
        @apply bg-bg-input-disabled;
      }
    }

    .login-button {
      @apply w-full bg-blue-500 rounded p-2 md:p-4 hover:bg-opacity-80 transition-all ease-in-out duration-100;

      &.disabled {
        @apply bg-opacity-80 cursor-not-allowed;
      }

      span {
        @apply text-gray-50 text-xl md:text-2xl font-medium md:font-bold;
      }
    }
  }
}
