@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  @apply w-2;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-[#aaa] rounded-full;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-[#555] rounded-full;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-[#757575] rounded-full;
}

input[type="text"],
input[type="number"],
input[type="password"] {
  @apply px-2 py-1 text-lg text-text-main font-normal border border-solid border-border-input bg-bg-input rounded transition ease-in-out duration-100;

  &::placeholder {
    @apply text-text-main transition-all ease-in-out duration-100 opacity-50;
  }
  &:hover {
    @apply border-border-input-hover;
  }
  &:focus {
    @apply outline-none border-border-input-focus;
  }

  &.is-valid {
    border-color: #27c24c !important;
  }
  &.is-invalid {
    border-color: #d32f2f !important;
  }

  &.disabled {
    @apply cursor-not-allowed opacity-80;
  }
}

input[type="radio"] {
  appearance: none;
  @apply w-6 h-6 rounded-full bg-bg-input border border-border-input cursor-pointer transition-all ease-in-out duration-100;

  &:hover {
    @apply border-border-input-hover;
  }
  &:checked {
    @apply bg-bg-radio-selected border-border-radio-selected;
  }
}

textarea {
  @apply p-2 text-sm text-text-main bg-bg-input leading-4 tracking-tight font-normal border border-solid border-border-input rounded transition ease-in-out duration-100;

  &::placeholder {
    @apply text-text-main opacity-40;
  }
  &:hover {
    @apply border-border-input-hover;
  }
  &:focus {
    @apply border-border-input-focus outline-none;
  }

  &.is-valid {
    border-color: #27c24c !important;
  }
  &.is-invalid {
    border-color: #d32f2f !important;
  }

  &.disabled {
    @apply cursor-not-allowed;
  }
}

.age-input {
  .title {
    @apply flex items-center pl-1 mb-1 text-text-main;
  }

  .field {
    @apply flex items-center justify-around gap-1;

    input[type="text"] {
      @apply border-0 rounded-none;

      &::placeholder {
        @apply text-sm text-text-main;
      }

      &:focus {
        @apply border-b-2;
      }
    }
  }
}

.form-control {
  @apply flex flex-col justify-end;

  label {
    @apply text-sm sm:text-base font-medium text-text-main;
  }

  .error {
    span {
      @apply text-sm text-red-400;
    }
  }
}

.form-checkbox {
  @apply flex items-center justify-center text-text-main w-6 min-w-6 h-6 min-h-6 rounded border border-solid border-border-input bg-bg-input hover:border-gray-400 transition-all ease-in-out duration-100;

  &.is-checked {
    @apply bg-bg-checkbox-selected border-border-checkbox-selected transition-all ease-in-out duration-100;
  }

  .icon {
    @apply text-text-checkbox transition-all ease-in-out duration-100;
  }
}

.main-app {
  @apply flex flex-col h-full gap-0 min-h-screen bg-bg-main transition-all ease-in-out duration-100;

  .header {
    @apply w-full h-14 md:h-24;
  }

  .footer {
    @apply bg-gray-700;
  }

  .content {
    flex: 1;
    @apply w-full flex flex-col;

    .containerized {
      @apply container mx-auto my-4;
    }
  }

  .page-wrapper {
    .page-totals {
      @apply grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-2 lg:gap-6 mb-4 mt-4;

      > div {
        @apply rounded-xl bg-gray-300 flex flex-col items-center text-center justify-center pt-2 sm:pt-4 pb-4 sm:pb-6 text-text-main bg-bg-card-main border border-solid border-border-card-main transition-all ease-in-out duration-100;

        span {
          @apply px-2;
        }
        span:first-child {
          @apply text-base sm:text-lg font-bold;
        }
        span:nth-child(2) {
          @apply text-2xl md:text-3xl lg:text-4xl font-bold;
        }
      }
    }

    .page-with-sidebar {
      @apply grid grid-cols-12 h-full;

      .sidebar {
        @apply z-50 -left-3/4 absolute h-full sm:static sm:col-span-4 lg:col-span-3 2xl:col-span-2 flex flex-col gap-0.5 px-2 py-8 sm:py-2 bg-bg-sidebar border-r border-solid border-border-sidebar transition-all ease-in-out duration-300;

        &.visible {
          @apply left-0;
        }

        .item {
          @apply flex items-center gap-2 md:gap-3 px-3 md:px-6 py-4 rounded-lg hover:bg-[black] hover:bg-opacity-15 text-text-main cursor-pointer md:text-lg transition-all ease-in-out duration-100;
        }
        .item.active {
          @apply bg-[black] bg-opacity-15;
        }
      }

      > .content {
        @apply col-span-12 sm:col-span-8 lg:col-span-9 2xl:col-span-10 relative bg-bg-settings;

        > .title {
          @apply pr-4 pl-10 py-4 sm:p-4 md:p-8 border-b border-solid border-border-sidebar;

          > span {
            @apply text-2xl text-text-main;
          }
        }
      }
    }

    .page-content {
      @apply p-2 md:p-4 transition-all ease-in-out duration-100;

      &.with-bg {
        @apply bg-bg-card-main;
      }

      &.with-border {
        @apply border border-solid border-border-main;
      }

      &.is-inner {
        @apply p-0;
      }

      .page-title {
        @apply flex items-center justify-between gap-4 mb-4;

        .title {
          @apply font-bold text-xl max-w-56 sm:max-w-full lg:text-3xl text-text-main truncate;
        }
      }

      .title-button {
        @apply flex items-center gap-2 px-2 py-[2px] border-2 border-solid border-border-button-title rounded-2xl;

        span {
          @apply text-sm text-text-button-title;
        }

        svg {
          @apply text-sm text-text-button-title;
        }

        &.enabled {
          @apply opacity-100 cursor-pointer;
        }

        &.disabled {
          @apply opacity-50 cursor-not-allowed;
        }
      }

      .filters {
        @apply mb-4 relative;

        .title {
          @apply w-full flex items-center justify-between;
        }
        .filter-actions {
          @apply flex flex-col sm:flex-row items-center gap-x-2 md:gap-x-4;
        }
      }

      .tabs {
        @apply grid;

        .tab {
          @apply flex items-center gap-2 justify-center col-span-1 cursor-pointer transition-all ease-in-out duration-100 py-3;

          > span {
            @apply flex items-center text-sm sm:text-base md:text-lg lg:text-xl text-text-main font-medium truncate px-1;
          }

          div {
            @apply inline-block sm:block max-w-12 sm:max-w-none truncate;
          }

          &.active {
            @apply border-b-2 border-solid border-border-tabs-active opacity-100;
          }
          &.inactive {
            @apply text-gray-600 border-b-2 border-solid border-border-tabs opacity-50;
          }
        }
      }

      .table-wrapper {
        @apply bg-bg-table-main border border-solid border-border-main rounded-lg;

        .external-header {
          @apply px-4 pt-4 flex items-center justify-between;

          .title-inner {
            span {
              @apply text-text-main text-base md:text-2xl font-semibold;
            }
          }
        }
      }

      .row-card {
        @apply rounded p-2 xl:p-4 bg-gray-50 border border-solid border-gray-200;
      }

      .document-wrapper {
        @apply mx-auto relative;

        .image-holder {
          @apply relative w-full aspect-square;

          .overlay {
            @apply z-10 absolute top-2 bg-gray-900 bg-opacity-60 px-2 py-1 rounded transition-all ease-in-out duration-100;

            span {
              @apply text-base text-white;
            }
          }

          img {
            @apply aspect-square cursor-pointer object-cover w-full h-full rounded hover:opacity-80 transition-all ease-in-out duration-150;
          }
        }
      }
    }

    .box-border-rounded {
      @apply rounded-2xl p-2 xl:p-4 border border-solid border-border-card-main text-text-main;

      &.with-bg {
        @apply bg-bg-table-main;
      }
    }

    .page-heading {
      @apply relative mb-3 md:mb-4 text-text-main;

      > span {
        @apply whitespace-nowrap md:whitespace-nowrap ml-2 sm:ml-0 col-span-1 text-xl md:text-2xl;
      }
    }

    .sortable-column-label {
      @apply font-bold;

      span {
        @apply text-link-main;

        .center-aligned {
          @apply block w-full text-center;
        }
      }

      svg {
        @apply absolute right-0 -mr-6;

        path {
          @apply text-blue-500;
        }
      }
    }

    .link {
      @apply text-link-main cursor-pointer;

      &:hover {
        @apply underline;
      }
    }
  }
}

.yarl__flex_center {
  background-color: rgba(0, 0, 0, 0) !important;
}

.yarl__portal {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.Pager-pager {
  padding: 0px !important;
}
.Pagination-pagination {
  .Pagination-rowsLabel {
    @apply text-text-main;
  }
  .MuiButtonBase-root {
    padding: 5px 7px !important;
    color: rgba(var(--text-main)) !important;

    svg {
      @apply text-text-main;
    }
  }

  .MuiButton-root .Pagination-text {
    @apply text-text-main;
  }
}
.PageSizeSelector-label {
  @apply text-text-main;
}
.PageSizeSelector-inputRoot {
  svg,
  .MuiInput-input {
    @apply text-text-main;
  }
}

.TableDetailToggleCell-toggleCell {
  padding: 4px 0px !important;
  width: 0px !important;
}

.TableDetailCell-active {
  padding: 8px 6px !important;
  background-color: rgba(var(--bg-table-expand)) !important;
}

.MuiTableCell {
  &.head {
    @apply border-b border-solid border-border-table-header;
  }
  &.body {
    @apply border-b border-solid border-border-table-body;
  }
}

.gm-style-iw-ch {
  @apply p-0 flex items-center;
}
