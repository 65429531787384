.incidents-table {
  .incidents-cell {
    @apply relative;

    .cell-wrapper {
      @apply grid grid-cols-12 items-center gap-2;
    }

    .cell-inner {
      @apply flex items-center flex-wrap;
    }
  }
}

.incident-marker {
  @apply relative z-20 bg-white p-2 rounded border border-solid border-gray-400 shadow-lg;

  &:after {
    content: " ";
    @apply absolute left-1/2 transform -translate-x-1/2 bottom-0 translate-y-1/2 w-3 h-3 rotate-45 bg-white border-r border-b border-solid border-gray-400;
  }

  span {
    @apply block text-sm w-max font-bold;
  }
}
