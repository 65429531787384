.car-details {
  @apply grid grid-cols-12 gap-x-2;

  > div,
  > span {
    text-wrap: wrap;
    @apply mb-1 break-words sm:text-base;

    &:nth-child(odd) {
      @apply font-semibold col-span-5;
    }
    &:nth-child(even) {
      @apply col-span-7;
    }
  }
}

.detail-card-car {
  .detail-row-car {
    @apply flex gap-2 flex-row mb-2;

    &:last-child {
      @apply mb-0;
    }

    > div {
      &:first-child {
        @apply w-2/5;
      }
      &:last-child {
        @apply w-3/5;
      }

      span {
        @apply font-medium;
      }
    }
  }
}

.map-controls {
  @apply flex  gap-[1px] flex-col border border-solid border-gray-200 bg-gray-200 bg-opacity-80;

  .zoom-in,
  .zoom-out {
    @apply w-8 h-8 flex items-center justify-center bg-[#fff] bg-opacity-80 cursor-pointer;
  }
}

.marker-car-tag {
  @apply z-30 relative flex flex-col text-center p-1 rounded cursor-pointer transition-all ease-in-out duration-100;

  &.is-hovered {
    @apply bg-blue-600;
    &::after {
      @apply bg-blue-600;
    }
  }
  &.is-available {
    @apply bg-gray-700;

    &::after {
      @apply bg-gray-700;
    }
  }
  &.is-under-maintenance {
    @apply bg-orange-600;

    &::after {
      @apply bg-orange-600;
    }
  }
  &.is-riding {
    @apply bg-green-700;

    &::after {
      @apply bg-green-700;
    }
  }
  &.is-unavailable {
    @apply bg-red-700;

    &::after {
      @apply bg-red-700;
    }
  }

  &::after {
    content: " ";
    @apply z-10 absolute left-1/2 transform -translate-x-1/2 bottom-0 translate-y-1/2 w-3 h-3 rotate-45 bg-blue-500;
  }
  .tag-title {
    @apply relative flex items-center gap-1 text-text-main;

    span {
      @apply z-20 relative text-gray-50;
    }
  }
  .tag-info {
    @apply mt-2 text-gray-50;
  }
}

.form-btn {
  @apply inline-flex items-center gap-1 px-2.5 py-1.5 border border-solid rounded cursor-pointer transition-all ease-in-out duration-100;
}

.car-document {
  .info {
    @apply text-text-main grid grid-cols-1 gap-2 md:flex md:items-center md:justify-between md:gap-6 mb-4;

    > div {
      @apply flex items-center gap-1;
    }
  }
  .document {
    @apply w-full mb-4;
  }
}
